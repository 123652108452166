// External imports
import Vue from 'vue';
import dragElement from './dragElement';

export const AllowDrag = {
	inserted(el, binding) {
		dragElement(document.getElementById(binding.value));
	},
};

Vue.directive('allow-drag', AllowDrag);
