// Make the DIV element draggable:

/**
 * This function defines the behavior of element when dragged
 * @param {object} elmnt DOM element
 */
function dragElement(elmnt) {
	var pos1 = 0,
		pos2 = 0,
		pos3 = 0,
		pos4 = 0;
	let content = document.getElementById(elmnt.id + '___BV_modal_content_');
	// otherwise, move the DIV from anywhere inside the DIV:
	elmnt.onmousedown = dragMouseDown;

	function dragMouseDown(e) {
		e = e || window.event;
		e.preventDefault();
		// get the mouse cursor position at startup:
		pos3 = e.clientX;
		pos4 = e.clientY;
		document.onmouseup = closeDragElement;
		// call a function whenever the cursor moves:
		document.onmousemove = elementDrag;
	}

	function elementDrag(e) {
		e = e || window.event;
		e.preventDefault();
		// calculate the new cursor position:
		pos1 = pos3 - e.clientX;
		pos2 = pos4 - e.clientY;
		pos3 = e.clientX;
		pos4 = e.clientY;
		let offset = content.getBoundingClientRect();
		let elmntLeft = parseInt(elmnt.style.left.replace('px', '')) || 0;
		let elmntTop = parseInt(elmnt.style.top.replace('px', '')) || 0;

		// Validate Position
		if (offset.right >= elmnt.offsetWidth) {
			elmnt.style.left = elmntLeft - 1 + 'px';
		}
		if (offset.left <= 0) {
			elmnt.style.left = elmntLeft + 1 + 'px';
		}
		if (Math.round(offset.top) <= 0) {
			elmnt.style.top = elmntTop + 1 + 'px';
		}
		if (Math.round(offset.bottom) >= elmnt.offsetHeight) {
			elmnt.style.top = elmntTop - 1 + 'px';
		}

		// set the element's new position:
		if (offset.right < elmnt.offsetWidth && offset.left > 0) {
			if (
				offset.right + 1 != elmnt.offsetWidth &&
				elmntLeft < elmnt.offsetLeft - pos1
			) {
				elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
			}
			if (offset.left - 1 != 0 && elmntLeft > elmnt.offsetLeft - pos1) {
				elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
			}
		}
		if (Math.round(offset.bottom) < elmnt.offsetHeight && Math.round(offset.top) > 0) {
			if (
				Math.round(offset.bottom) + 1 != elmnt.offsetHeight &&
				elmntTop < elmnt.offsetTop - pos2
			) {
				elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
			}
			if (Math.round(offset.top) - 1 != 0 && elmntTop > elmnt.offsetTop - pos2) {
				elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
			}
		}
	}

	function closeDragElement() {
		// stop moving when mouse button is released:
		document.onmouseup = null;
		document.onmousemove = null;
	}
}

export default dragElement;
