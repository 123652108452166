import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import Calendar from '@/components/Calendar/Calendar.vue';
import CalendarDouble from '@/components/CalendarDouble/CalendarDouble.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import FunctionalCalendar from '@/components/DateRange/FunctionalCalendar.vue';
import Badge from '@/components/Badge/StatusBadge.vue';

import ScheduleService from '@/services/API/schedule.service';
import socketClient from '@/services/SOCKET';

import ScheduleConst from '@/constants/ScheduleConst';
import CommonConst from '@/constants/CommonConst';
import { checkMobileScreen } from '@/store/modules/ScreenWidth';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import scrollToTop from '@/mixin/scrollToTop';

import moment from 'moment';
import { mapState } from 'vuex';

/* eslint-disable no-mixed-spaces-and-tabs */
const SELECTIVE_NOTI_CONTENTS = checkMobileScreen()
	? '「 スケジュール 」 をクライアントに\n送信します'
	: '「 スケジュール 」 をクライアントに送信します';
const PLAIN_NOTI_CONTENTS = 'クライアントが承認しませんでした';

let currentDate = moment().format('YYYY/MM/DD');

export default {
	name: 'ScheduleScreen',
	mixins: [updateProjectStatusMixin, scrollToTop],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			monthNames: ScheduleConst.MONTH_NAMES,
			dayNames: ScheduleConst.DAY_NAMES,
			minDate: currentDate,
			minDeliveryDate: currentDate,
			calendarData: {},
			//Edit directLink hearing 28/07/2023 by HoaDQ
			directLink: 'hearing',
			screenIcon: 'schedule-icon',
			screenName: 'スケジュール',
			// List categories to display on right side of main body
			eventCategories: [...ScheduleConst.EVENT_CATEGORIES],
			// List events to display on calendar (left side of main body)
			calendarEvents: ScheduleConst.CALENDAR_EVENTS,
			remarksContent: '',
			selectiveContents: SELECTIVE_NOTI_CONTENTS,
			plainContents: PLAIN_NOTI_CONTENTS,
			errorContents: '',
			showBottomButton: false,
			showSaveButton: true,
			isSchdeduleSaved: false,
			scheduleStatus: '',
			scheduleInfo: {
				comment: '',
				delivery_date: null,
				creative_end_date: null,
				creative_start_date: null,
				estimate_end_date: null,
				estimate_start_date: null,
				project_id: '',
				proposal_end_date: null,
				proposal_start_date: null,
				decision_end_date: null,
				decision_start_date: null,
				update_user: '',
				//add
				title_creative: '',
				title_proposal: '',
			},
			showStatusBadge: false,
			showReasonContent: false,
			reasonContent: '',
			plainModalId: 'schedule-plain-modal',
			selectiveModalId: 'schedule-selective-modal',
			disabledSaveButton: true,
			showEditButton: false,
			showEstimateTotal: false,
			enableDatePicker: false,
			disabledEditButton: true,
			bottomButtonName: '',
			bottomButtonType: '',
			badgeContent: '',
			mailContent: '',
			mailTitle: '',
			subject: '',
			isSendCreator: false,

			// Proposal date to watch
			proposalDate: {
				start: '',
				end: '',
			},
			// Temporary proposalEndate: to check update deliveryDate
			tempProposalEndDate: '',
			// Prevent updating delivery_date base on proposal data when fetching data from server
			isFetching: false,

			// Show main content after fetch data from server successfully
			isShowContent: false,

			estimateInputs: [
				{
					status: true,
					title: '稼働時間',
					caption: '時間',
					value: undefined,
					disable: false,
				},
				{
					status: true,
					title: '',
					caption: '時間',
					value: undefined,
					disable: false,
				},
				{
					status: true,
					title: '',
					caption: '時間',
					value: undefined,
					disable: false,
				},
				{
					status: true,
					title: '',
					caption: '時間',
					value: undefined,
					disable: false,
				},
				{
					status: true,
					title: '',
					caption: '時間',
					value: undefined,
					disable: false,
				},
				{
					status: true,
					title: '',
					caption: '時間',
					value: undefined,
					disable: true,
				},
			],
		};
	},
	components: {
		ScreenHeader,
		SaveButtonArea,
		BottomButton,
		Calendar,
		NotificationModal,
		FunctionalCalendar,
		Badge,
		CalendarDouble,
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}schedule`
						: `${this.projectInfo['process_client_url']}schedule`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}schedule`
					: `${this.projectInfo['process_client_url']}schedule`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},
	watch: {
		// Binding data to display on calendar
		eventCategories: {
			//Made by HACHIX
			handler: function (newVal) {
				this.proposalDate =
					newVal[ScheduleConst.PROPOSAL_INDEX].datePickerData.dateRange;

				const reflectEvents = newVal.filter((event) => {
					return (
						(event.datePickerData.dateRange.start !== '' &&
							event.datePickerData.dateRange.end !== '') ||
						event.datePickerData.selectedDate !== ''
					);
				});
				for (const element of reflectEvents) {
					let eventIndex = element.id - 1;
					// Get index's event of eventsCategories
					if (this.calendarEvents[eventIndex].categoryId === element.id) {
						this.calendarEvents[eventIndex].start =
							element.datePickerData.dateRange.start;
						this.calendarEvents[eventIndex].end = element.datePickerData.dateRange.end;
						if (eventIndex === ScheduleConst.DELIVERY_INDEX) {
							this.calendarEvents[eventIndex].start =
								element.datePickerData.selectedDate;
							this.calendarEvents[eventIndex].end = element.datePickerData.selectedDate;
						}
					}
				}
				let newInputs = this.estimateInputs.filter(
					(e) => (e.value === '' || e.value === undefined) && e.disable == false
				);
				this.disabledSaveButton =
					this._validateScheduleData(newVal) || newInputs.length > 0;
			},
			deep: true,
		},
		projectInfo: {
			handler: function (newVal) {
				this.eventCategories.map((element) => {
					if (element.scheduleEvent === 'delivery') {
						element.datePickerData.selectedDate = newVal.delivery_date ?? '';
						this.scheduleInfo.delivery_date = newVal.delivery_date ?? null;
						return;
					}
					element.datePickerData.dateRange.start =
						newVal[element.scheduleEvent + '_start_date'] ?? '';
					element.datePickerData.dateRange.end =
						newVal[element.scheduleEvent + '_end_date'] ?? '';

					this.scheduleInfo[element.scheduleEvent + '_start_date'] =
						newVal[element.scheduleEvent + '_start_date'] ?? null;
					this.scheduleInfo[element.scheduleEvent + '_end_date'] =
						newVal[element.scheduleEvent + '_end_date'] ?? null;
				});
			},
			deep: true,
		},

		'proposalDate.end'(newVal) {
			this.minDeliveryDate = newVal;
			if (newVal) {
				let deliveryDate =
					this.eventCategories[ScheduleConst.DELIVERY_INDEX].datePickerData
						.selectedDate;

				if (
					new Date(this.tempProposalEndDate).getTime() <
						new Date(deliveryDate).getTime() &&
					new Date(newVal).getTime() < new Date(deliveryDate).getTime()
				)
					return;

				if (!this.isFetching) {
					this.eventCategories[
						ScheduleConst.DELIVERY_INDEX
					].datePickerData.selectedDate = newVal;
				}

				this.tempProposalEndDate = newVal;
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} newStatus  Screen Status
		 */
		scheduleStatus(newStatus) {
			if (
				newStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM ||
				newStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.SCHEDULE]);
			} else {
				const redDotIndexs = this.redDotIndexs.filter(
					(screenId) => screenId !== CommonConst.SCREEN_ID.SCHEDULE
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		estimateInputs: {
			handler: function (newVal) {
				let newInputs = newVal.filter(
					(e) => (e.value === '' || e.value === undefined) && e.disable == false
				);
				if (newInputs.length > 0) {
					this.estimateInputs[ScheduleConst.LAST_INPUT_ESTIMATE_IDX]['value'] =
						undefined;
					this.disabledSaveButton = true;
				} else {
					this.disabledSaveButton = this._validateScheduleData(this.eventCategories);
					let sum = 0;
					for (let index = 0; index < newVal.length - 1; index++) {
						sum += parseFloat(newVal[index]['value']);
					}
					sum = sum.toFixed(2);
					if (parseInt(sum) == sum) {
						sum = parseInt(sum);
					} else if (parseInt(sum * 10) == sum * 10) {
						sum = parseInt(sum * 10) / 10;
					}
					this.estimateInputs[ScheduleConst.LAST_INPUT_ESTIMATE_IDX]['value'] = sum;
				}
			},
			deep: true,
		},
	},
	methods: {
		async onSaveButtonClick() {
			this.$store.commit('setIsAppProcessing', true);
			const isScheduleValid = !this._validateScheduleData(this.eventCategories);
			if (isScheduleValid) {
				let scheduleInfo = { ...this.scheduleInfo };
				delete scheduleInfo.id;
				scheduleInfo['comment'] = this.remarksContent;
				scheduleInfo = this._filterScheduleInfo(scheduleInfo);
				scheduleInfo['update_user'] = this.userId;
				scheduleInfo['project_id'] = this.projectId;
				scheduleInfo['status'] = CommonConst.SCREEN_STATUS.SAVE;
				// add code title_creative, title_proposal 28/07/2023 HoaDQ
				scheduleInfo['title_creative'] = this.eventCategories[2]['title'];
				scheduleInfo['title_proposal'] = this.eventCategories[3]['title'];
				scheduleInfo['estimate_estimate_hour'] = parseFloat(
					this.estimateInputs[0]['value']
				);
				scheduleInfo['decision_estimate_hour'] = parseFloat(
					this.estimateInputs[1]['value']
				);
				scheduleInfo['creative_estimate_hour'] = parseFloat(
					this.estimateInputs[2]['value']
				);
				scheduleInfo['proposal_estimate_hour'] = parseFloat(
					this.estimateInputs[3]['value']
				);
				scheduleInfo['delivery_estimate_hour'] = parseFloat(
					this.estimateInputs[4]['value']
				);

				console.log('___________________', scheduleInfo);

				let isSchdeduleSaved = await this._updateScheduleData(scheduleInfo);
				if (isSchdeduleSaved) {
					this.isSchdeduleSaved = true;
					this.showEditButton = true;
					this.enableDatePicker = false;
					this.showReasonContent = false;
					this.showStatusBadge = false;
					this.bottomButtonType = 'direct-screen-btn';
					this.bottomButtonName = 'キックオフミーティングに進む';
					this.showSaveButton = false;
					this.scheduleStatus = CommonConst.SCREEN_STATUS.SAVE;
					this.showEstimateTotal = true;
					this.scrollToTop('#topDiv');
				}
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		async onEditButtonClick() {
			this.showEditButton = false;
			this.showEstimateTotal = false;
			this.enableDatePicker = true;
			this.showBottomButton = false;
			this.showSaveButton = true;
			this.showStatusBadge = false;
			this.scrollToTop('#topDiv');
		},

		onSend() {
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		async onDirectLink() {
			this.$store.commit('setIsAppProcessing', true);

			let scheduleInfo = {
				status: CommonConst.SCREEN_STATUS.FINISH,
			};
			await this._updateScheduleData(scheduleInfo);
			this.showBottomButton = true;
			this.scheduleStatus = CommonConst.SCREEN_STATUS.FINISH;

			this.$router.push(this.directLink);
			let confirmInfo = {
				screenId: CommonConst.SCREEN_ID.SCHEDULE,
				confirmInfo: true,
			};

			if (this.projectInfo['status'] === CommonConst.SCREEN_ID.SCHEDULE) {
				this.$emit('on-send-confirm-info', confirmInfo);
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'schedule_direct',
					},
					room: this.projectId,
				});
				this.$emit('on-update-project-status', CommonConst.SCREEN_ID.SCHEDULE + 1);
				await this.updateProjectStatus(
					this.projectId,
					CommonConst.SCREEN_ID.SCHEDULE + 1
				);
			} else {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'schedule_confirm_required',
					},
					room: this.projectId,
				});
			}

			// send Mail case 17
			// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「キックオフミーティング」スタート`;
			// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「キックオフミーティング」スタート`;
			// this.mailContent = `「キックオフミーティング」を\nスタートします。`;
			// this.isSendCreator = true;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['responsible_user'],
			// ]);
			// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「キックオフミーティング」スタート`;
			// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「キックオフミーティング」スタート`;
			// this.mailContent = `「キックオフミーティング」を\nスタートします。`;
			// this.isSendCreator = false;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['client_id'],
			// ]);

			this.$store.commit('setIsAppProcessing', false);
		},

		onCheckScreenStatus(status) {
			if (!status || status === '') {
				this.enableDatePicker = true;
			} else if (status == CommonConst.SCREEN_STATUS.SAVE) {
				this.showBottomButton = true;
				this.bottomButtonType = 'direct-screen-btn';
				this.bottomButtonName = 'キックオフミーティングに進む';
				this.disabledEditButton = false;
				this.enableDatePicker = false;
				this.showSaveButton = false;
				this.showEditButton = true;
				this.showEstimateTotal = true;
			} else if (status == CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.showBottomButton = true;
				this.bottomButtonType = 'direct-screen-btn';
				this.bottomButtonName = 'キックオフミーティングに進む';
				this.showStatusBadge = true;
				if (this.scheduleInfo['reason'] === '' || this.scheduleInfo['reason'] == null) {
					this.badgeContent = 'クライアント承認待ち';
				} else {
					this.badgeContent = 'クライアント承認待ち';
				}
				this.showSaveButton = false;
				this.showEditButton = true;
				this.disabledEditButton = true;
			} else if (status == CommonConst.SCREEN_STATUS.DENY_CONFIRM) {
				this.disabledEditButton = false;
				this.showReasonContent = true;
				this.showStatusBadge = false;
				this.reasonContent = this.scheduleInfo['reason'];
				this.showEditButton = true;
				this.enableDatePicker = false;
				this.showSaveButton = false;
				this.showEstimateTotal = true;
			} else if (status == CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM) {
				let scheduleInfo = {
					status: CommonConst.SCREEN_STATUS.FINISH,
				};
				this._updateScheduleData(scheduleInfo);
			} else if (status == CommonConst.SCREEN_STATUS.FINISH) {
				this.showBottomButton = false;
				this.showEditButton = false;
				this.showSaveButton = false;
				this.enableDatePicker = false;
				this.showEstimateTotal = true;
			}
		},
		async onSendCalendarData() {
			this.$store.commit('setIsAppProcessing', true);

			let scheduleInfo = {
				status: CommonConst.SCREEN_STATUS.WAITING_CONFIRM,
			};
			let result = await this._updateScheduleData(scheduleInfo);
			if (result) {
				this.showStatusBadge = true;
				this.scheduleStatus = CommonConst.SCREEN_STATUS.WAITING_CONFIRM;

				if (this.reasonContent !== '') {
					this.badgeContent = 'クライアント承認待ち';
				}
				this.showBottomButton = false;
				this.disabledEditButton = true;

				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'schedule_confirm_required',
					},
					room: this.projectId,
				});

				// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「スケジュール」を送信しました`;
				// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「スケジュール」を送信しました`;
				// this.mailContent = `「スケジュール」を\n送信しました。`;
				// this.isSendCreator = true;
				// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				// 	this.projectInfo['responsible_user'],
				// ]);
				// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「スケジュール」が届きました`;
				// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「スケジュール」が届きました`;
				// this.mailContent = `「スケジュール」が\n届きました。`;
				// this.isSendCreator = false;
				// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				// 	this.projectInfo['client_id'],
				// ]);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		_validateScheduleData(datas) {
			return datas.some((data) => {
				if (data.id == 5 && data.datePickerData.selectedDate !== '') {
					return false;
				}
				return (
					data.datePickerData.dateRange.start === '' ||
					data.datePickerData.dateRange.end === ''
				);
			});
		},

		/**
		 * From estimateInfo from DB filter into estimateInput
		 * @param {Array} estimateInfo
		 */
		_filterEstimateInfo(estimateInfo) {
			let sum = 0;
			for (let index = 0; index < estimateInfo.length; index++) {
				if (estimateInfo.includes(null)) {
					sum = undefined;
				} else {
					this.estimateInputs[index]['value'] = estimateInfo[index];
					sum = sum + estimateInfo[index];
				}
			}
			this.estimateInputs[ScheduleConst.LAST_INPUT_ESTIMATE_IDX]['value'] = sum;
		},

		/**
		 * Get Schedule Info from DB
		 * @param {Number} projectId
		 */
		async _getScheduleInfo(projectId) {
			this.isFetching = true;
			try {
				let response = await ScheduleService.get(projectId);

				if (!response || response.status !== 200) {
					throw 'Get Schedule Info failed';
				}

				//Will create new schedule info immediatly if not have schedule info
				if (Object.keys(response.data).length === 0) {
					this.onCheckScreenStatus(response.data['status']);
					this._createNewScheduleInfo();

					setTimeout(() => {
						this.isFetching = false;
					});
					return true;
				}
				//add code get title_creative, title_proposal 28/07/2023 by HoaDQ
				this.title_creative = response.data['title_creative'];
				this.title_proposal = response.data['title_proposal'];
				this.scheduleInfo = response.data;
				this.remarksContent = response.data['comment'];
				this.scheduleStatus = response.data['status'];
				const estimateInfo = [
					response.data['estimate_estimate_hour'],
					response.data['decision_estimate_hour'],
					response.data['creative_estimate_hour'],
					response.data['proposal_estimate_hour'],
					response.data['delivery_estimate_hour'],
				];

				this.onCheckScreenStatus(this.scheduleInfo['status']);
				this._filterEventCategories(this.scheduleInfo);
				this._filterEstimateInfo(estimateInfo);
				let newInputs = this.estimateInputs.filter(
					(e) => (e.value === '' || e.value === undefined) && e.disable == false
				);
				this.disabledSaveButton =
					this._validateScheduleData(this.eventCategories) || newInputs.length > 0;
				setTimeout(() => {
					this.isFetching = false;
				});
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Filter Calendar and DateTimePicker show data
		 * @param {Object} data: API's response data
		 */
		//Made by HACHIX
		_filterEventCategories(data) {
			console.log('data', data);
			for (let i = 0; i < ScheduleConst.EVENT_CATEGORIES.length; i++) {
				if (
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_start_date'] &&
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_end_date']
				) {
					this.eventCategories[i].datePickerData.dateRange.start = moment(
						data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_start_date']
					).format('YYYY/MM/DD');
					this.eventCategories[i].datePickerData.dateRange.end = moment(
						data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_end_date']
					).format('YYYY/MM/DD');
				}
				if (data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_date']) {
					this.eventCategories[i].datePickerData.selectedDate = moment(
						data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_date']
					).format('YYYY/MM/DD');
				}
				//add code 28/07/2023 by HoaDQ
				const { isEdit, title } = this.checkIsEdited(
					ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent
				);
				if (isEdit) {
					this.eventCategories[i].title = title;
				}
			}
		},
		//add code checkIsEdited 28/07/2023 by HoaDQ
		checkIsEdited(scheduleEvent) {
			switch (scheduleEvent) {
				case 'creative':
					return {
						isEdit: true,
						title: this.title_creative,
					};
				case 'proposal':
					return {
						isEdit: true,
						title: this.title_proposal,
					};
				default:
					return {
						isEdit: false,
						title: '',
					};
			}
		},

		/**
		 * Filter Schedule Data for update to DB
		 * @param {Object} data Schedule Info
		 * @returns filtered Schedule Info
		 */
		//Made by HACHIX
		_filterScheduleInfo(data) {
			for (let i = 0; i < ScheduleConst.EVENT_CATEGORIES.length; i++) {
				if (this.eventCategories[i].datePickerData.selectedDate) {
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_date'] =
						this.eventCategories[i].datePickerData.selectedDate;
					continue;
				}
				if (
					this.eventCategories[i].datePickerData.dateRange.start === '' ||
					this.eventCategories[i].datePickerData.dateRange.end === ''
				) {
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_start_date'] = null;
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_end_date'] = null;
				} else {
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_start_date'] =
						this.eventCategories[i].datePickerData.dateRange.start;
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_end_date'] =
						this.eventCategories[i].datePickerData.dateRange.end;
				}
			}
			return data;
		},

		/**
		 * Create new Schedule Record
		 */
		async _createNewScheduleInfo() {
			let scheduleInfo = { ...this.scheduleInfo };
			scheduleInfo['update_user'] = this.userId;
			scheduleInfo['project_id'] = this.projectId;
			scheduleInfo['status'] = '';
			// add code title_creative, title_proposal 28/07/2023 HoaDQ
			scheduleInfo['title_creative'] = this.eventCategories[2]['title'];
			scheduleInfo['title_proposal'] = this.eventCategories[3]['title'];
			try {
				let response = await ScheduleService.post({
					doc_content: scheduleInfo,
				});
				if (response && response.status === 200) {
					console.log('Create schedule info succesfully');
				}
			} catch (error) {
				console.log(`Post schedule failed: ${error}`);
			}
		},

		/**
		 * Update Schedule info Database when click in Save Button
		 */
		async _updateScheduleData(scheduleInfo) {
			try {
				let response = await ScheduleService.update(this.projectId, scheduleInfo);
				if (response && response.status === 200) {
					console.log('Update schedule info succesfully');
					this.onCheckScreenStatus(scheduleInfo['status']);
				}
				return true;
			} catch (error) {
				console.log(`Update schedule info failed: ${error}`);
				return false;
			}
		},
	},
	async created() {
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'schedule_retry':
					this.$store.commit('setIsAppProcessing', true);

					await this._getScheduleInfo(this.projectId);
					this.showStatusBadge = false;

					this.$store.commit('setIsAppProcessing', false);
					break;
				default:
					break;
			}
		});

		socketClient.listen('new_schedule_confirm', () => {
			this.bottomButtonType = 'direct-screen-btn';
			this.showBottomButton = true;
			this.bottomButtonName = 'キックオフミーティングに進む';
			this.showStatusBadge = false;
			this.scheduleStatus = CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM;
		});

		await this._getScheduleInfo(this.projectId);
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);
		moment.locale('ja');
		this.isShowContent = true;

		this.$store.commit('setIsAppProcessing', false);
	},
};
