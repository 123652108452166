// import dragElement from './NotificationDragFunc';
import { AllowDrag } from '../../directives/HxAllowDrag/allowDrag.directive';
import HxPdfExporter from '@/components/HxPdfExporter/HxPdfExporter.vue';
export default {
	name: 'NotificationModal',
	components: { HxPdfExporter },
	directives: {
		AllowDrag,
	},
	props: {
		selectiveContents: {
			type: String,
			required: false,
		},
		plainContents: {
			type: String,
			required: false,
		},
		isSelective: {
			type: Boolean,
		},
		selectiveModalId: {
			type: String,
		},
		plainModalId: {
			type: String,
		},
		isPlainSelective: {
			type: Boolean,
		},
		subContents: {
			type: String,
		},
		yesButtonTitle: {
			type: String,
			default: 'はい',
		},
		noButtonTitle: {
			type: String,
			default: 'いいえ',
		},
		/**
		 * @binding {String} color theme of button
		 * @value : primary
		 * @value : secondary
		 */
		btnColor: {
			type: String,
			default: 'primary',
		},
		/**
		 * @binding {String} size of button
		 * @value : medium
		 * @value : large
		 */
		btnSize: {
			type: String,
			default: 'medium',
		},
		callEventOnHide: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isConfirmRequire: false,
			inputContent: '',
		};
	},
	methods: {
		onSelectYesClick() {
			this.isConfirmRequire = true;
			this.$emit('on-confirm-require', this.isConfirmRequire);
			this.$bvModal.hide(`${this.selectiveModalId}`);
			if (this.isSelective) {
				this.$bvModal.show(`${this.plainModalId}`);
			}
		},

		onSelectNoClick() {
			this.isConfirmRequire = false;
			this.$emit('on-declined-require', this.isConfirmRequire);
			this.$bvModal.hide(`${this.selectiveModalId}`);
		},

		onDirectLinkClick() {
			this.$emit('on-direct-link');
			this.$bvModal.hide(`${this.plainModalId}`);
		},

		onConfirmError() {
			this.$bvModal.hide('error-modal');
			this.$bvModal.hide(`${this.errorModalId}`);
		},

		onClose() {
			this.$bvModal.hide(`${this.plainModalId}`);
			this.$emit('on-modal-close');
		},
		onHide() {
			console.log(this.callEventOnHide)
			if (this.callEventOnHide) {
				this.$emit('on-modal-hide');
			}
		}
		// handlePlainModalShow() {
		// 	// Hide Plain Modal after 5s
		// 	if (this.plainModalId) {
		// 		setTimeout(() => {
		// 			this.$bvModal.hide(`${this.plainModalId}`);
		// 		}, 1500);
		// 	}
		// },
	},
};
