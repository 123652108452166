var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"notification",attrs:{"id":"notif-modal"}},[_c('b-modal',{attrs:{"id":_vm.selectiveModalId,"hide-footer":"","hide-header":"","centered":"","no-close-on-esc":"","content-class":"normal-modal-content","body-class":"normal-modal-body","dialog-class":"normal-modal-dialog"}},[_c('div',{directives:[{name:"allow-drag",rawName:"v-allow-drag",value:(_vm.selectiveModalId),expression:"selectiveModalId"}]},[_c('div',{staticClass:"notification__content"},[_c('div',{staticClass:"notification__text"},[_c('label',[_vm._v(_vm._s(_vm.selectiveContents))])]),_c('div',{staticClass:"notification__btnArea"},[_c('button',{staticClass:"notification__btn notification__btn__Yes",class:{
							'notification__btn--medium': _vm.btnSize === 'medium',
							'notification__btn--large': _vm.btnSize === 'large',
							'notification__btn__Yes--primary': _vm.btnColor === 'primary',
							'notification__btn__Yes--secondary': _vm.btnColor === 'secondary',
						},on:{"click":_vm.onSelectYesClick}},[_vm._v(" "+_vm._s(_vm.yesButtonTitle)+" ")]),_c('button',{staticClass:"notification__btn notification__btn__No",class:{
							'notification__btn--medium': _vm.btnSize === 'medium',
							'notification__btn--large': _vm.btnSize === 'large',
							'notification__btn__No--primary': _vm.btnColor === 'primary',
							'notification__btn__No--secondary': _vm.btnColor === 'secondary',
						},on:{"click":_vm.onSelectNoClick}},[_vm._v(" "+_vm._s(_vm.noButtonTitle)+" ")])])])])]),_c('b-modal',{attrs:{"id":_vm.plainModalId,"hide-footer":"","hide-header":"","centered":"","hide-backdrop":"","no-close-on-esc":"","content-class":"normal-modal-content","body-class":"normal-modal-body","dialog-class":"normal-modal-dialog"},on:{"hide":_vm.onHide}},[_c('div',{directives:[{name:"allow-drag",rawName:"v-allow-drag",value:(_vm.plainModalId),expression:"plainModalId"}]},[_c('div',[_c('img',{staticClass:"btnClose",attrs:{"src":require("../../assets/ForComponent/CloseBtn.svg"),"aria-label":""},on:{"click":_vm.onClose}})]),_c('div',{staticClass:"notification__content"},[_c('label',[_vm._v(_vm._s(_vm.plainContents))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }