export default {
	name: 'ScreenHeader',
	props: {
		screenIcon: {
			type: String,
		},
		screenName: {
			type: String,
		},
		showNotice: {
			type: Boolean,
		},
		screenNotice: {
			type: String,
		},
		isPlainTitle: {
			type: Boolean,
		},
	},
};
